/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        /*jslint browser: true*/
/*global $, jQuery, Modernizr, google, _gat*/
/*jshint strict: true */

var isMobile = false;
var isDesktop = false;


//RESIZE EVENTS
$(window).resize(function () {

	Modernizr.addTest('ipad', function () {
		return !!navigator.userAgent.match(/iPad/i);
	});

	if (!Modernizr.ipad) {
	       initializeMainMenu();
	   }

	   /* Reload the page to make sure everything fits in its media query*/
		//window.location.href = window.location.href;
	});

	/*
	|--------------------------------------------------------------------------
	| DOCUMENT READY
	|--------------------------------------------------------------------------
	*/
	$(document).ready(function() {


		"use strict";

		/** INIT FUNCTIONS **/
		initializeMainMenu();



		/*
		|--------------------------------------------------------------------------
		| Menu Fixed (multipage)
		|--------------------------------------------------------------------------
		*/

	  $(window).scroll(function () {
	     if( $(window).width() > 1025 ){

	        if($(window).scrollTop() > 0){
	           $('#mainHeader').addClass('fixedHeader');


	       }else{
	           $('#mainHeader').removeClass('fixedHeader');


	       }
	   }
	  });






		 /*
	    |--------------------------------------------------------------------------
	    |  form placeholder for IE
	    |--------------------------------------------------------------------------
	    */
	    if(!Modernizr.input.placeholder){

	    	$('[placeholder]').focus(function() {
	    		var input = $(this);
	    		if (input.val() == input.attr('placeholder')) {
	    			input.val('');
	    			input.removeClass('placeholder');
	    		}
	    	}).blur(function() {
	    		var input = $(this);
	    		if (input.val() == '' || input.val() == input.attr('placeholder')) {
	    			input.addClass('placeholder');
	    			input.val(input.attr('placeholder'));
	    		}
	    	}).blur();
	    	$('[placeholder]').parents('form').submit(function() {
	    		$(this).find('[placeholder]').each(function() {
	    			var input = $(this);
	    			if (input.val() == input.attr('placeholder')) {
	    				input.val('');
	    			}
	    		});
	    	});

	    }


	    /*
	    |--------------------------------------------------------------------------
	    | TOOLTIP
	    |--------------------------------------------------------------------------
	    */

	    $('.tips').tooltip({placement:'auto'});




	    /*
	    |--------------------------------------------------------------------------
	    | OWL CAROUSEL
	    |--------------------------------------------------------------------------
	    */

	    if($('.nekoDataOwl, .neko-data-owl').length){

	        $( '.nekoDataOwl, .neko-data-owl' ).each(function( index ) {
	        	var $this = $(this);
	            $this.owlCarousel(
	            {
	                items:$this.data('neko_items'),
	                navigation:$this.data('neko_navigation'),
	                singleItem:$this.data('neko_singleitem'),
	                autoPlay:$this.data('neko_autoplay'),
	                itemsScaleUp:$this.data('neko_itemsscaleup'),
	                navigationText:['<i class="icon-left-open"></i>','<i class="icon-right-open"></i>'],
	                pagination:$this.data('neko_pagination'),
	                paginationNumbers:$this.data('neko_paginationnumbers'),
	                autoHeight:$this.data('neko_autoheight'),
	                mouseDrag:$this.data('neko_mousedrag'),
	                transitionStyle:$this.data('neko_transitionstyle'),
	                responsive:true,
	                lazyLoad : true,
	                addClassActive:true,
	                itemsDesktop :[1450,4],
	                afterInit: function(){

	                   if( Modernizr.mq('only all and (min-width: 769px)') ){
		                   	$('.caption-wrapper').css('display', 'none');
		                   	$('.active .caption-wrapper').css('display', 'block');
		                   	$('.active .caption-content-position', $this).children().each(function(index, val) {
		                   		$(this).addClass('animated '+$(this).data('neko_animation'));
		                   	});
	                  }else{
	                  	$('.caption-wrapper').css('display', 'block');
	                  }



	              },
	              beforeMove:  function(){

	               if( Modernizr.mq('only all and (min-width: 769px)') ){

		               	$('.caption-wrapper').css('display', 'none');
		               	$('.active .caption-wrapper').css('display', 'block');
		               	$('.caption-content-position').children().each(function(index, val) {
		               		$(this).removeClass('animated '+$(this).data('neko_animation'));
		               	});

	              }else{
	              	$('.caption-wrapper').css('display', 'block');
	              }

		          },
		          afterMove:  function(){

		           if( Modernizr.mq('only all and (min-width: 769px)') ){
		           	$('.caption-wrapper').css('display', 'none');
		           	$('.active .caption-wrapper').css('display', 'block');
		           	$('.active .caption-content-position', $this).children().each(function(index, val) {

		           		$(this).addClass('animated '+$(this).data('neko_animation'));
		           	});

		          }else{
	              	$('.caption-wrapper').css('display', 'block');
	              }
	      	}
	  });
	});
	}



	    /*
	    |--------------------------------------------------------------------------
	    | ROLLOVER BTN
	    |--------------------------------------------------------------------------
	    */

	    if($('.imgHover').length){

	    	if(!Modernizr.csstransitions && !Modernizr.touch){

	           $('.imgHover figure').addClass('noCss3');
	           $('.imgHover figure').hover(
	             function() {


	                $('img', this).stop(true, false).animate({
	                   bottom: '+=40px'
	               }, 400, 'easeOutQuad',function() {}).end();


	                var captionHeight = $('figcaption', this).outerHeight(true);

	                $('figcaption', this).stop(true, false).animate({

	                   bottom: captionHeight

	               }, 400, 'easeOutQuad',function() {}).end();



	            }, function() {

	                $('img', this).stop(true, false).animate({
	                   bottom: '0'
	               }, 400, 'easeOutQuad',function() {}).end();

	                $('figcaption', this).stop(true, false).animate({
	                   bottom: 0
	               }, 400, 'easeOutQuad',function() {}).end();

	            }
	            );
	       }

	   }



	    /*
	    |--------------------------------------------------------------------------
	    | ROLLOVER BTN
	    |--------------------------------------------------------------------------
	    */

	    $('.socialIcon').hover(
	    	function () {
	    		$(this).stop(true, true).addClass('socialHoverClass', 300);
	    	},
	    	function () {
	    		$(this).removeClass('socialHoverClass', 300);
	    	});





	    $('.tabs li, .accordion h2').hover(
	    	function () {
	    		$(this).stop(true, true).addClass('speBtnHover', 300);
	    	},
	    	function () {
	    		$(this).stop(true, true).removeClass('speBtnHover', 100);
	    	});



	    /*
	    |--------------------------------------------------------------------------
	    | ALERT
	    |--------------------------------------------------------------------------
	    */
	    $('.alert').delegate('button', 'click', function() {
	    	$(this).parent().fadeOut('fast');
	    });




	/*
	|--------------------------------------------------------------------------
	| APPEAR
	|--------------------------------------------------------------------------
	*/
	if($('.activateAppearAnimation').length){

		$('.reloadAnim').click(function (e) {
			$(this).parent().parent().find('img[data-nekoanim]').attr('class', '').addClass('img-responsive');
			e.preventDefault();
		});
	}



	//END DOCUMENT READY
	});


	/*
	|--------------------------------------------------------------------------
	| EVENTS TRIGGER AFTER ALL IMAGES ARE LOADED
	|--------------------------------------------------------------------------
	*/
	$(window).load(function() {

		"use strict";

	    /*
	    |--------------------------------------------------------------------------
	    | PRELOADER
	    |--------------------------------------------------------------------------
	    */
	    if($('#status').length){
	        $('#status').fadeOut(); // will first fade out the loading animation
	        $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
	        $('body').delay(350).css({'overflow':'visible'});
	    }


	    /*
	    |--------------------------------------------------------------------------
	    | ISOTOPE USAGE FILTERING
	    |--------------------------------------------------------------------------
	    */
	    if($('.isotopeWrapper').length){

	    	var $container = $('.isotopeWrapper');
	    	var $resize = $('.isotopeWrapper').attr('id');
	        // initialize isotope

	        $container.isotope({
	        	layoutMode: 'sloppyMasonry',
	        	itemSelector: '.isotopeItem',
	            resizable: false,
	            masonry: {
	            	columnWidth: $container.width() / $resize
	            }

	        });


	        //var rightHeight = $('#works').height();
	        $('#filter a').click(function(e){


	        	//$('#works').height(rightHeight);
	        	$('#filter a').removeClass('current');


	        	$(this).addClass('current');
	        	var selector = $(this).attr('data-filter');

	        	$container.isotope({
	        		filter: selector,
	        		animationOptions: {
	        			duration: 300,
	        			easing: 'easeOutQuart'
	        		}
	        	});

	        	if (isDesktop === true && $('section[id^="paralaxSlice"]').length){
	              setTimeout(function(){
	                 $.stellar('refresh');
	             }, 1000);
	          }

	          e.preventDefault();
	          return false;
	      });


	        $(window).smartresize(function(){
	        	$container.isotope({
	                // update columnWidth to a percentage of container width
	                masonry: {
	                	columnWidth: $container.width() / $resize
	                }
	            });
	        });


	    }









	    /**PROCESS ICONS**/
	    $('.iconBoxV3 a').hover(function() {

	    	if(Modernizr.csstransitions) {

	    		$(this).stop(false, true).toggleClass( 'hover', 150);
	    		$('i', this).css('-webkit-transform', 'rotateZ(360deg)');
	    		$('i', this).css('-moz-transform', 'rotateZ(360deg)');
	    		$('i', this).css('-o-transform', 'rotateZ(360deg)');
	    		$('i', this).css('transform', 'rotateZ(360deg)');

	    	}else{

	    		$(this).stop(false, true).toggleClass( 'hover', 150);

	    	}

	    }, function() {

	    	if(Modernizr.csstransitions) {
	    		$(this).stop(false, true).toggleClass( 'hover', 150);
	    		$('i', this).css('-webkit-transform', 'rotateZ(0deg)');
	    		$('i', this).css('-moz-transform', 'rotateZ(0deg)');
	    		$('i', this).css('-o-transform', 'rotateZ(0deg)');
	    		$('i', this).css('transform', 'rotateZ(0deg)');

	    	}else{

	    		$(this).stop(false, true).toggleClass( 'hover', 150);
	    	}

	    });




	    if (isDesktop === true && $('section[id^="paralaxSlice"]').length )
	    {

	    	$(window).stellar({
	    		horizontalScrolling: false,
	    		responsive:true
	    	});
	    }





	//END WINDOW LOAD
	});

	/*
	|--------------------------------------------------------------------------
	| FUNCTIONS
	|--------------------------------------------------------------------------
	*/







	/* CONTACT FROM */
	/*
	$(function() {
		"use strict";
		if( $("#contactfrm").length ){

			$.validator.setDefaults({
				highlight: function(element) {
					$(element).closest('.form-group').addClass('has-error has-feedback');
					if(!$(element).closest('.form-group').find('.form-control-feedback').length){
						$(element).closest('.form-group').append('<span class="icon-cancel form-control-feedback"></span>');
					}

				},
				unhighlight: function(element) {
					$(element).closest('.form-group').removeClass('has-error has-feedback');
					$(element).closest('.form-group').find('.form-control-feedback').remove();
				},
				errorElement: 'span',
				errorClass: 'help-block',
				errorPlacement: function(error, element) {
					if(element.parent('.input-group').length) {
						error.insertAfter(element.parent());
					} else {
						error.insertAfter(element);
					}
				}
			});


			$("#contactfrm").validate({
				submitHandler: function(form) {

					$(form).ajaxSubmit({
						target: ".result",
						success: function(){
							if($('.result .alert-success').length){
								$("#contactfrm").trigger('reset');

							}
						}
					});
				},
				onkeyup: false,
				onclick: false,
				rules: {
					name: {
						required: true,
						minlength: 3
					},
					email: {
						required: true,
						email: true
					},
					phone: {
						required: true,
						minlength: 10,
						digits:true
					},
					comment: {
						required: true,
						minlength: 10,
						maxlength: 350
					}
				}
			});
		}

	});
	*/

	/* CONTACT FROM */

	/* FLEXSLIDER INNER INFO CUSTOM ANIMATION */
	function animateTxt(curSlide, action){
		"use strict";
		if(action === 'in'){
			var i = 0;
			var animaDelay = 0;

			$('.slideN'+curSlide+':not([class*=clone])>.caption').css('display', 'block');

			$('.slideN'+curSlide+':not([class*=clone])>.caption>div').each(function( ) {
				if(Modernizr.csstransitions) {

					$(this).css('-webkit-animation-delay', animaDelay+'s');
					$(this).css('-moz-animation-delay', animaDelay+'s');
					$(this).css('-0-animation-delay', animaDelay+'s');
					$(this).css('-ms-animation-delay', animaDelay+'s');
					$(this).css('animation-delay-delay', animaDelay+'s');

					$(this).show().addClass('animated').addClass($(this).attr('data-animation'));


	                // $(this).show('slow', function() {
	                //     $(this).addClass('animated').addClass($(this).attr('data-animation'));
	                // });


		}else{
			var timing;
			$('.slideN'+curSlide+':not([class*=clone])>.caption>div').hide();
			if (i === 0){timing = 0;}else if(i === 1){timing = 300;} else{ timing = 300 * i;}
			$(this).delay(timing).fadeIn('fast');
		}
		i++;
		animaDelay = animaDelay+0.2;


	});

		}else{
			var j = 0;
			$('.slideN'+curSlide+':not([class*=clone])>.caption').css('display', 'none');

			$('.slideN'+curSlide+':not([class*=clone])>.caption>div').each(function( ) {
				if(Modernizr.csstransitions) {

					$(this).removeClass($(this).attr('data-animation')).removeClass('animated').hide();

				}else{
					$(this).hide();
				}
				j++;
			});
		}

	}



	/* MAIN MENU (submenu slide and setting up of a select box on small screen)*/
	function initializeMainMenu() {

		"use strict";
		var $mainMenu = $('#mainMenu').children('ul');


		/* Mobile */
		if(Modernizr.mq('only all and (max-width: 1024px)') ) {


				// Responsive Menu Events
				var addActiveClass = false;

				$("a.hasSubMenu").unbind('click');
				$('li',$mainMenu).unbind('mouseenter mouseleave');

				$("a.hasSubMenu").on("click", function(e) {

					var $this = $(this);
					e.preventDefault();


					addActiveClass = $this.parent("li").hasClass("Nactive");


					$this.parent("li").removeClass("Nactive");
					$this.next('.subMenu').slideUp('fast');



					if(!addActiveClass) {
						$this.parents("li").addClass("Nactive");
						$this.next('.subMenu').slideDown('fast');
					}else{
						$this.parent().parent('li').addClass("Nactive");
					}

					return;

				});

	          /* Tablet */
	      }else{


	         $("li", $mainMenu).removeClass("Nactive");
	         $('a', $mainMenu).unbind('click');


	         $('li',$mainMenu).hover(

	            function() {

	               var $this = $(this);
	               var $subMenu = $this.children('.subMenu');


	               if( $subMenu.length ){
	                  $this.addClass('hover').stop();
	              }else {

	                  if($this.parent().is($(':gt(1)', $mainMenu))){

	                     $this.stop(false, true).fadeIn('slow');

	                 }
	             }


	             if($this.parent().is($(':gt(1)', $mainMenu))){

	              $subMenu.stop(true, true).fadeIn(200,'easeInOutQuad');
	              $subMenu.css('left', $subMenu.parent().outerWidth(true));


	          }else{

	              $subMenu.stop(true, true).delay( 200 ).fadeIn(200,'easeInOutQuad');

	          }

	      }, function() {

	       var $this = $(this);
	       var $subMenu = $this.children('ul, div');

	       if($this.parent().is($(':gt(1)', $mainMenu))){


	          $this.children('ul').hide();
	          $this.children('ul').css('left', 0);


	      }else{

	          $this.removeClass('hover');
	          $subMenu.stop(true, true).delay( 200 ).fadeOut();
	      }

	      if( $subMenu.length ){$this.removeClass('hover');}

	  });

	}
	}





	/* ANALYTICS */
	/*
	function gaSSDSLoad (acct) {
		"use strict";
		var gaJsHost = (("https:" === document.location.protocol) ? "https://ssl." : "http://www."),
		pageTracker,
		s;
		s = document.createElement('script');
		s.src = gaJsHost + 'google-analytics.com/ga.js';
		s.type = 'text/javascript';
		s.onloadDone = false;
		function init () {
			pageTracker = _gat._getTracker(acct);
			pageTracker._trackPageview();
		}
		s.onload = function () {
			s.onloadDone = true;
			init();
		};
		s.onreadystatechange = function() {
			if (('loaded' === s.readyState || 'complete' === s.readyState) && !s.onloadDone) {
				s.onloadDone = true;
				init();
			}
		};
		document.getElementsByTagName('head')[0].appendChild(s);
	}
	*/



	/*

	jQuery(function($){
		"use strict";
		if($('#superSizedSlider').length){


			$.supersized({

	                    // Functionality
	                    slideshow               :   1,          // Slideshow on/off
	                    autoplay                :   1,          // Slideshow starts playing automatically
	                    start_slide             :   1,          // Start slide (0 is random)
	                    stop_loop               :   0,          // Pauses slideshow on last slide
	                    random                  :   0,          // Randomize slide order (Ignores start slide)
	                    slide_interval          :   12000,      // Length between transitions
	                    transition              :   1,          // 0-None, 1-Fade, 2-Slide Top, 3-Slide Right, 4-Slide Bottom, 5-Slide Left, 6-Carousel Right, 7-Carousel Left
	                    transition_speed        :   1000,       // Speed of transition
	                    new_window              :   1,          // Image links open in new window/tab
	                    pause_hover             :   0,          // Pause slideshow on hover
	                    keyboard_nav            :   1,          // Keyboard navigation on/off
	                    performance             :   1,          // 0-Normal, 1-Hybrid speed/quality, 2-Optimizes image quality, 3-Optimizes transition speed // (Only works for Firefox/IE, not Webkit)
	                    image_protect           :   1,          // Disables image dragging and right click with Javascript

	                    // Size & Position
	                    min_width               :   0,          // Min width allowed (in pixels)
	                    min_height              :   0,          // Min height allowed (in pixels)
	                    vertical_center         :   1,          // Vertically center background
	                    horizontal_center       :   1,          // Horizontally center background
	                    fit_always              :   0,          // Image will never exceed browser width or height (Ignores min. dimensions)
	                    fit_portrait            :   1,          // Portrait images will not exceed browser height
	                    fit_landscape           :   0,          // Landscape images will not exceed browser width

	                    // Components
	                    slide_links             :   'blank',    // Individual links for each slide (Options: false, 'num', 'name', 'blank')
	                    thumb_links             :   0,          // Individual thumb links for each slide
	                    thumbnail_navigation    :   0,          // Thumbnail navigation
	                    slides                  :   [           // Slideshow Images
	                    {image : './images/slider/super/supersized-1.jpg', title : '<h1>We are a creative agency</h1><a href="#team" class="btn btn-primary">learn more</a>', thumb : '', url : ''},

	                    {image : './images/slider/super/supersized-2.jpg', title : '<h1>We build beautiful websites</h1><a href="#works" class="btn btn-primary">check our portfolio</a>', thumb : '', url : ''},

	                    {image : './images/slider/super/supersized-3.jpg', title : '<h1>That\'s how we like it</h1><a href="#contact" class="btn btn-primary">call us</a>', thumb : '', url : ''}
	                    ],

	                    // Theme Options
	                    progress_bar            :   0,          // Timer for each slide
	                    mouse_scrub             :   0

	                });
	}
	});
	*/

	/* TO TOP BUTTON */

	function toTop(mobile){

	/*
	 if(mobile == false){

	    if(!$('#nekoToTop').length)
	        $('body').append('<a href="#" id="nekoToTop"><i class="icon-up-open"></i></a>');

	    $(window).scroll(function () {
	        if ($(this).scrollTop() > 100) {
	            $('#nekoToTop').slideDown('fast');
	        } else {
	            $('#nekoToTop').slideUp('fast');
	        }
	    });

	    $('#nekoToTop').click(function (e) {
	        e.preventDefault();
	        $("html, body").animate({
	            scrollTop: 0
	        }, 800, 'easeInOutCirc');

	    });
	}else{

	    if($('#nekoToTop').length)
	        $('#nekoToTop').remove();

	}
	*/

	}

	$(function() {
	  $('a[href*="#"]:not([href="#"])').click(function() {
	    var headerHeight = 60;
	    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
	      var target = $(this.hash);
	      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	      if (target.length) {
	        $('html, body').animate({
	          scrollTop: target.offset().top + headerHeight
	        }, 1000);
	        return false;
	      }
	    }
	  });
	});


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
